import React, { useRef, useState } from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import { redirectToStore } from '../../utils/redirect'
import ActiverScreenerImg from '../../assets/images/active-screener/active-screener.webp'
import SimpleAndEffectiveImg from '../../assets/images/active-screener/simple-effective.webp'
import SetupImg from '../../assets/images/active-screener/setup.svg'
import EaseImg from '../../assets/images/active-screener/ease.svg'
import ChoicesImg from '../../assets/images/active-screener/choices.svg'
import ControlImg from '../../assets/images/active-screener/control.svg'
import UnwantedImg from '../../assets/images/active-screener/unwanted.webp'
import CentricImg from '../../assets/images/active-screener/centric.webp'
import IntegrationImg from '../../assets/images/active-screener/integration.webp'
import ArrowDownimg from '../../assets/images/active-screener/arrow-down.svg'
import StreamlinedImg from '../../assets/images/active-screener/streamlined.webp'

const ActiveScreener = ({ data }) => {

  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="BlueMail Active Screener | BlueMail App"
        postDescription="Welcome to Active Screener. The smart solution that puts you in the driver's seat of your email world."
        postImage="/img/OG/og_image_active-screener.png"
        postURL="features-functions/active-screener"
        postSEO
      />
      <Navbar />
      <div className="features-functions active-screener">
        <div className="container pt-120">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                    <p style={{opacity: '0.7', textTransform: 'uppercase'}}>Introducing</p>
                    <h2 className="m-left"><span>Active Screener:</span> <br/> Your Personal Email Protector</h2>
                    <p className="p-20"> Take Control of Your Inbox Are you overwhelmed by emails from unknown senders? Welcome 
                        to Active Screener – the smart solution that puts you in the driver's seat of your email world. It's like having 
                        a personal assistant who knows exactly who you want to hear from.
                    </p>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 m-auto pt-3">
                    <img className="auto" src={ActiverScreenerImg} alt="Activer Screener"/>
                </div>
            </div>
        </div>
        <div className="container pt-120">
            <div className="highlights">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-xs-12 m-auto pb-3">
                        <h2>Highlights:</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-xs-12 m-auto">
                        <div className="highlights-container">
                            <div className="img">
                                <img src={SetupImg} alt="Seamless Setup"/>
                            </div>
                            <div>
                                <p>Seamless Setup: Integrates effortlessly with your current email system.</p>
                            </div>
                        </div>
                        <div className="highlights-container">
                            <div className="img">
                                <img src={EaseImg} alt="Screen with Ease"/>
                            </div>
                            <div>
                                <p>Screen with Ease: Make quick decisions on new email contacts.</p>
                            </div>
                        </div>
                        <div className="highlights-container">
                            <div className="img">
                                <img src={ChoicesImg} alt="Seamless Setup"/>
                            </div>
                            <div>
                                <p>Revisit Choices: Easily modify your screening preferences.</p>
                            </div>
                        </div>
                        <div className="highlights-container">
                            <div className="img">
                                <img src={ControlImg} alt="Seamless Setup"/>
                            </div>
                            <div>
                                <p>Discreet Control: Your screening actions are confidential.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container pt-120">
            <div className="row m-rev">
                <div className="col-lg-6 col-md-6 col-xs-12 m-auto pt-3">
                    <img className="auto" src={SimpleAndEffectiveImg} alt="Simple And Effective"/>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 m-auto simple">
                    <h2>How It Works:<br /> <span>Simple and Effective</span></h2>
                    <p>First Impressions Matter:</p>
                    <p className="small">Encounter a new sender? Active Screener steps in. Decide if their emails belong in your inbox. Accept them, and their messages appear. Decline, and you won’t see them again. It’s straightforward.</p>
                    <p>Flexible Decisions:</p>
                    <p className="small">Changed your mind about a sender? No worries. In the Screener History, you can reverse previous choices, allowing blocked senders back in, along with their emails from the past 90 days.</p>
                    <p>Your Inbox, Your Rules:</p>
                    <p className="small">Active Screener enhances, not replaces, your spam filter. It works post-spam filtering, giving you the ultimate say in who gets your attention.</p>
                </div>                
            </div>
        </div>
        <div className="container pt-120">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 m-auto text-center pb-3">
                    <h2>Why Active Screener is a <span>Game-Changer</span></h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-xs-12 m-auto text-center">
                    <div className="game-changer one">
                        <img src={UnwantedImg} alt="Eliminate Unwanted Emails"/>
                        <p className="pt-3">Eliminate Unwanted Emails:</p>
                        <p className="small">From persistent sales pitches to unsolicited newsletters, Active Screener keeps your inbox focused and relevant.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12 m-auto text-center">
                    <div className="game-changer two">
                        <img src={CentricImg} alt="Eliminate Unwanted Emails"/>
                        <p className="pt-3">Privacy-Centric:</p>
                        <p className="small">Your decisions to screen out senders remain private. Senders won't get notified, ensuring your choices stay discreet.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12 m-auto text-center">
                    <div className="game-changer tree">
                        <img src={IntegrationImg} alt="Eliminate Unwanted Emails"/>
                        <p className="pt-3">Easy Integration:</p>
                        <p className="small">Active Screener enhances, not replaces, your spam filter. It works post-spam filtering, giving you the ultimate say in who gets your attention.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container pt-120 pb-100">
            <div className="row m-rev">
                <div className="col-lg-6 col-md-6 col-xs-12 m-auto text-center">
                    <img className="auto" src={StreamlinedImg} alt="Streamlined"/>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12 m-auto">
                    <h2>Ready for a <span>Streamlined</span> Email Experience?</h2>
                    <p className="p-20"> Embrace the peace of mind that comes with a well-managed inbox. Try Active Screener today and start focusing on the emails that truly matter to you, where you call the shots.</p>
                    <a
                          href="#"
                          activeClassName="active"
                          onClick={e => {
                            e.preventDefault()
                            redirectToStore(e)
                          }}
                          className={"default-btn mt-1"}
                        >
                          Download <img src={ArrowDownimg} alt="Arrow down"/> 
                        </a>
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default ActiveScreener

export const query = graphql`
  query ActiveScreenerPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
